import React, {useState} from 'react'
import ApolloClient from 'apollo-boost'
import {ApolloProvider} from 'react-apollo'
import ModalContext from './src/context/modalContext'

const client = new ApolloClient({
  uri: 'https://beta-api.lifeonscreen.com/graphql',
})

const defaultValues = {
  show: false,
  firstName: '',
  workEmail: '',
}

const ModalManager = ({children}) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <ModalContext.Provider
      value={{
        onClose: () => setShowModal(false),
        onOpen: () => setShowModal(true),
        showModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

// eslint-disable-next-line react/prop-types,react/display-name
export default ({element}) => (
  <ApolloProvider client={client}>
    <ModalManager>{element}</ModalManager>
  </ApolloProvider>
)
